audio {
  width: 100%;
  float: left;
}

audio:focus {
  outline: none;
}

.container-audio {
  width: 100%;
  overflow: hidden;
  height: 40px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.container-audio span {
  margin-left: 6px;
}

audio::-webkit-media-controls-panel {
  background-color: #fff;
  padding: 0;
}

audio::-webkit-media-controls-fullscreen-volume-slider {
  display: block;
}

audio::-webkit-media-controls-closed-captions-container {
  display: block;
}

audio::-webkit-media-controls-closed-captions-track-list {
  display: block;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

audio::-webkit-media-controls-download-button {
  display: none;
}

.audio-close {
  background: linear-gradient(-180deg, white 0%, #f4f4f5 100%);
  overflow: hidden;
  margin-left: -2rem;
  font-size: 12px;
}

.close-button {
  float: right;
  padding: 9px 5px;
  cursor: pointer;
}
