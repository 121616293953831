
.App {
  font-family: 'Source Sans Pro-Regular', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro-Regular', sans-serif
}

.MuiInputBase-input {
  height: 10px !important;
}

.MuiButton-label {
  text-transform: none;
}

.MuiListItemIcon-root {
  min-width: 16px !important;
}

.next,
.prev {
    padding: 8px 13px;
    margin: auto 0;
    cursor: pointer;
    color: black;
    transition: 0.6s ease;
    border-radius: 2px;
    border: 1px solid transparent;
    z-index: 2; }
.next:hover,
.prev:hover {
    background: linear-gradient(-180deg, white 0%, #f4f4f5 100%);
    border-color: #bcbcc2; }

.next {
    order: 3;
    right: 8px;
    position: absolute;
    top: 60%;
}

.prev {
    order: 3;
    left: 8px;
    position: absolute;
    top: 60%;
}

.MuiTablePagination-input.MuiTablePagination-selectRoot {
  width: 200px;
}

.MuiTablePagination-input.MuiTablePagination-selectRoot svg {
  position: relative;
  left: 146px;
}

.MuiTablePagination-input .MuiInputBase-inputSelect {
  position: absolute;
  width: 166px;
  right: 0px;
}
